import styles from './indicator.module.scss';

interface CarouselIndicatorProps {
  totalSlides: number;
  currentIndex: number;
}

export const Indicator:React.FC<CarouselIndicatorProps> = ({totalSlides, currentIndex}) => {
  const idx = currentIndex - 2;
  return  <div className={styles.indicatorContainer}>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <div className={styles.indicator} key={index}></div>
      ))}
      <div
        className={styles.slider}
        style={{
          width: `${(window.innerWidth - 96) / 5}px`,
          transform: `translateX(calc(${idx * 100}% + ${24 * idx}px))`,
        }}
      ></div>
    </div>
}