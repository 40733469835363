import { useState } from 'react';
import styles from './index.module.scss';

export const ContactModal = () => {
  const [brandName, setBrandName] = useState('');
  const [website, setWebsite] = useState('');
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <div className={styles.contactModal}>
      <h1>Contact Us</h1>
      <div className={styles.col}>
        <p>Brand Name*</p>
        <input onChange={(e) => setBrandName(e.target.value)} />
      </div>
      <div className={styles.col} style={{ marginTop: '32px' }}>
        <p>Website*</p>
        <input onChange={(e) => setWebsite(e.target.value)} />
      </div>
      <div className={styles.col} style={{ marginTop: '32px' }}>
        <p>Product Category*</p>
        <input onChange={(e) => setCategory(e.target.value)} />
      </div>
      <div className={styles.col} style={{ marginTop: '32px' }}>
        <p>Email*</p>
        <input onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className={styles.col} style={{ marginTop: '32px' }}>
        <p>Message*</p>
        <textarea onChange={(e) => setMessage(e.target.value)} />
      </div>
      <button className={styles.joinButton}>
        Submit
      </button>
    </div>
  );
};
