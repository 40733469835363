export const HeartSVG = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_48_351)'>
      <path
        d='M22.5 9.5625C22.5 16.125 12.7697 21.4369 12.3553 21.6562C12.2461 21.715 12.124 21.7458 12 21.7458C11.876 21.7458 11.7539 21.715 11.6447 21.6562C11.2303 21.4369 1.5 16.125 1.5 9.5625C1.50174 8.02146 2.11468 6.54404 3.20436 5.45436C4.29404 4.36468 5.77146 3.75174 7.3125 3.75C9.24844 3.75 10.9434 4.5825 12 5.98969C13.0566 4.5825 14.7516 3.75 16.6875 3.75C18.2285 3.75174 19.706 4.36468 20.7956 5.45436C21.8853 6.54404 22.4983 8.02146 22.5 9.5625Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_48_351'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowLeftSVG = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_48_336)'>
      <path
        d='M20 26L10 16L20 6'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_48_336'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowRightSVG = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M19.8431 6.92863L26.2071 13.2926C26.5976 13.6831 26.5976 14.3163 26.2071 14.7068L19.8431 21.0708C19.4526 21.4613 18.8195 21.4613 18.4289 21.0708C18.0384 20.6802 18.0384 20.0471 18.4289 19.6566L23.0858 14.9997L1.5 14.9997L1.5 12.9997L23.0858 12.9997L18.4289 8.34285C18.0384 7.95232 18.0384 7.31916 18.4289 6.92863C18.8195 6.53811 19.4526 6.53811 19.8431 6.92863Z'
      fill='black'
    />
  </svg>
);
