import { LandingPage } from './landing';
import './sentry';
import './index.css';
// import Main from './main';
import ReactDOM from 'react-dom/client';
import { Route, Switch } from "wouter";

const App = () => {

  return <Switch>
    <Route path={'/'}>
      <LandingPage />
    </Route>
  </Switch>
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
