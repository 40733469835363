import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Indicator } from './indicator';
import { Navs } from './nav';
import { ArrowLeftSVG, ArrowRightSVG, HeartSVG } from './svg';
import { ContactModal } from './modal';
import { InView, useInView } from 'react-intersection-observer';
import Reveal, { Fade } from "react-awesome-reveal";
import { Modal } from 'antd';
import cx from 'classnames'
import { motion, AnimatePresence } from "framer-motion";
export const CardOne = () => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.desc}>
          <h2>Customized</h2>
          <h2>Moodboard</h2>
          <p>
            Empower customers to showcase their style by creating Personalized Mood Boards with
            Favorite Brand Products, Inspiring Others in a Vibrant Fashion Community
          </p>
        </div>
      </div>
      <div className={styles.right}>
        <h3>Creator: Emily</h3>
        <div className={styles.carousel}>
          <div className={styles.btn}>
            <ArrowLeftSVG />
          </div>
          <div className={styles.blocks}>
            <div className={styles.block} />
          </div>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.row}>
            <HeartSVG />
            <p>1,532</p>
          </div>
          <p style={{ marginLeft: 'auto' }}>View full moodboard</p>
        </div>
        <h3>Creator: Emily</h3>
        <div className={styles.carousel}>
          <div className={styles.btn}>
            <ArrowLeftSVG />
          </div>
          <div className={styles.blocks}>
            <div className={styles.block} />
          </div>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.row}>
            <HeartSVG />
            <p>1,532</p>
          </div>
          <p style={{ marginLeft: 'auto' }}>View full moodboard</p>
        </div>
      </div>
    </div>
  );
};

export const CardTwo = () => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.desc}>
          <h2>Brand</h2>
          <h2>ranking</h2>
          <p>
            Elevate your brand with consumer-centric brand rankings, showcasing brand influence and
            offering personalized recommendations for unparalleled visibility, enhanced credibility,
            and increased audience engagement.
          </p>
        </div>
      </div>
      <div className={styles.right}>
        <h3 style={{ fontSize: '36px', alignSelf: 'center', marginLeft: 0 }}>Top Trendy Brand</h3>
        <div className={styles.circles} style={{ marginTop: '24px' }}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
        </div>
        <div className={styles.circles} style={{ marginTop: '24px' }}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
        </div>
        <div className={styles.circles} style={{ marginTop: '24px' }}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
        </div>
      </div>
    </div>
  );
};

export const CardThree = () => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.desc}>
          <h2>Pop-up</h2>
          <h2>Event</h2>
          <p>
            Join us at our global offline exhibitions to strengthen brand identity, enhancing
            brand-user interaction and participation.
          </p>
        </div>
      </div>
      <div className={styles.right}>
        <h3 style={{ fontSize: '36px', marginLeft: 0 }}>Upcoming Events</h3>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.img} />
            <div className={styles.itemDesc}>
              <h4>Next Gen Glam</h4>
              <div className={styles.row}>
                <span>From</span>
                <span className={styles.price}>$50</span>
                <div className={styles.btn} />
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img} />
            <div className={styles.itemDesc}>
              <h4>Next Gen Glam</h4>
              <div className={styles.row}>
                <span>From</span>
                <span className={styles.price}>$50</span>
                <div className={styles.btn} />
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img} />
            <div className={styles.itemDesc}>
              <h4>Next Gen Glam</h4>
              <div className={styles.row}>
                <span>From</span>
                <span className={styles.price}>$50</span>
                <div className={styles.btn} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardFour = () => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.desc}>
          <h2>All-in-one</h2>
          <h2>sales</h2>
          <h2>backend</h2>
          <p>
            Experience hassle-free E-commerce and Dropshipping with our simplified operational
            solutions.
          </p>
        </div>
      </div>
      <div className={styles.right} style={{ padding: 0 }}>
        <img alt='' src='/assets/card4.png' />
      </div>
    </div>
  );
};

export const CardFive = () => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div className={styles.desc}>
          <h2>Data driven</h2>
          <h2>insights</h2>
          <p>
            Optimize Your Strategy with Real-Time Data Insights Tailored to Consumer Behavior for
            Precision Marketing
          </p>
        </div>
      </div>
      <div className={styles.right} style={{ padding: 0 }}>
        <img alt='' src='/assets/card5.png' />
      </div>
    </div>
  );
};

const bgList = [
  '/assets/gray-background-with-shadow.png',
  '/assets/modern-colorful-gradient-mesh-color-background',
  '/assets/blur-colorful-background-blurred-colorful-with-grain-noise-effect-background.png',
  '/assets/free-abstract-plain-white-background-wallpaper1.png',
  'assets/free-abstract-plain-white-background-wallpaper.png',
];
import { keyframes } from "@emotion/react";
const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const LandingPage = () => {
  const [currentSlide, setCurrentSlider] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const divref0 = useRef<any>(null)
  const divref1 = useRef<any>(null)

  const [track, setTrack] = useState(false)
  console.log('track', track)
  const [top, setT] = useState(1)
  const [bottom, setB] = useState(1)
  const [perc, setP] = useState(1)

  useEffect(() => {
    if (divref0.current && divref1.current) {
      const bound1 = (divref1.current.node as unknown as HTMLDivElement).getBoundingClientRect()
      const bound0 = (divref0.current.node as unknown as HTMLDivElement).getBoundingClientRect()

      // const bound1 = (divref0.current as unknown as HTMLDivElement).getBoundingClientRect()
      setT(bound0.bottom)
      setB(bound1.top)
    }
  }, [divref0, divref1])

  useEffect(() => {
    const handleScroll = (ev: any) => {
      // ev
      const percent = window.scrollY / (bottom - top)
      setP(percent)
    };
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [bottom, top])
  const p = Math.min(4, Math.max(0, 5 * perc - 1))
  const percent = p * 0.25
  console.log(percent)

  useEffect(() => {
    let p = 0
    if (percent > 0.07) p++
    if (percent > 0.33) p++
    if (percent > 0.55) p++
    if (percent > 0.78) p++
    setCurrentSlider(p)
  }, [percent])
  return (
    <div className={styles.main}>
      <Navs onClick={() => setShowModal(e => !e)} />
      <div className={styles.part_1}>
        <div className={styles.desc}>
          <Reveal keyframes={customAnimation} duration={500} delay={0} triggerOnce>
            <div className={styles.title}>
              Redefining
              <br />
              Brand Promotion
              <br />
              With Us
            </div>
          </Reveal>
          <Reveal keyframes={customAnimation} duration={500} delay={100} triggerOnce>
            <p>
              Personalized recommendations derived from user data, facilitated brand connections
              <br />
              through offline pop-up events, enhancing engagement levels, and enabling precise
              <br />
              targeted marketing strategies.
            </p>
          </Reveal>
          <Reveal keyframes={customAnimation} duration={500} delay={200} triggerOnce>
            <button className={styles.joinButton}
              onClick={() => setShowModal(true)}>
              <span>Join Now</span>
              <div className={styles.arrow}>
                <ArrowRightSVG />
              </div>
            </button>
          </Reveal>
        </div>
      </div>
      <div
        className={styles.part_2}
        style={{ backgroundImage: `url(\'${bgList[currentSlide]}\')` }}
      // onClick={() => {
      //   setCurrentSlider(currentSlide === 4 ? 0 : currentSlide + 1);
      // }}
      >
        <Indicator totalSlides={5} currentIndex={p} />
        <InView as="div" ref={divref0} onChange={(inView, entry) => {
          // console.log(entry.boundingClientRect.y, inView)
          const j = entry.boundingClientRect.y
          if (inView && j > window.innerHeight / 2) {
            // scroll down
            setTrack(true)
          }
          if (inView && j < window.innerHeight / 2) {
            // scroll up
            setTrack(false)
          }
        }}></InView>
        <div className={styles.content}>
          <div className={styles.panel}>
            <AnimatePresence mode="wait">
              {
                currentSlide == 0 && <motion.div
                  key={"1"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                ><CardOne />
                </motion.div>
              }{
                currentSlide == 1 &&
                <motion.div
                  key={"2"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                ><CardTwo />
                </motion.div>
              }{
                currentSlide == 2 &&
                <motion.div
                  key={"3"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                ><CardThree />
                </motion.div>
              }{
                currentSlide == 3 &&
                <motion.div
                  key={"4"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                ><CardFive />
                </motion.div>
              }{
                currentSlide == 4 &&
                <motion.div
                  key={"5"}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                ><CardFive />
                </motion.div>
              }
            </AnimatePresence>
          </div>
          {/* <InView as="div" onChange={(inView, entry) => setCurrentSlider(4)}> */}
        </div>
        <InView as="div" ref={divref1} onChange={(inView, entry) => {
          console.log(entry.boundingClientRect.y, inView)
          const j = entry.boundingClientRect.y
          if (inView && j > window.innerHeight / 2) {
            // scroll down
            setTrack(false)
          }
          if (inView && j < window.innerHeight / 2) {
            // scroll up
            setTrack(true)
          }
        }}></InView>
      </div>
      <div className={styles.part_3}>
        <Reveal keyframes={customAnimation} duration={500} delay={0} triggerOnce>
          <h1>
            What <span>benefits</span> we can offer to you?
          </h1>
        </Reveal>
        <div className={styles.contents}>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/1.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a1.png' />
              <h4>
                Enhance Brand <br />
                Global Visibility
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Enhance global visibility using data-driven rankings, customized recommendations, and
              engaging international pop-up events.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/2.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a2.png' />
              <h4>
                Direct Brand-User <br />
                Connections
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Enable direct connections between brands and target users for impactful interactions
              and smarter marketing.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/3.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a3.png' />
              <h4>
                Engagement & <br />
                Creative Collaboration
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Create, share, and interact with moodboards, enhancing the community engagement and
              user retention.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/4.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a4.png' />
              <h4>
                Hyper-expand <br />
                Brand Reach
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Data tracking provides precise recommendations, unveiling new brands and products,
              expanding users' purchasing options.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/5.png' />
            <div className={styles.row}>
              <h4 style={{ color: '#647e9c', fontFamily: 'Kalam' }}>Boast Sales</h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Boost sales by connecting brands with users, driving hyper-effective marketing and
              community growth.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/6.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a6.png' />
              <h4>
                Streamline <br />
                Operations
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Effortlessly manage and publish vendor products, with automated store and inventory
              synchronization.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/7.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a7.png' />
              <h4>
                Simplified <br />
                Order Management
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Data tracking provides precise recommendations, unveiling new brands and products,
              expanding users' purchasing options.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/8.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a8.png' />
              <h4>
                Precision Regional <br />
                Marketing Strategy
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Boost sales by connecting brands with users, driving hyper-effective marketing and
              community growth.
            </p>
          </div>
          <div className={styles.card}>
            <img className={styles.header} alt='' src='/assets/cards/9.png' />
            <div className={styles.row}>
              <img className={styles.icon} alt='' src='/assets/cards/a9.png' />
              <h4>
                Trend <br />
                Analysis
              </h4>
            </div>
            <div style={{ marginTop: '16px' }} />
            <p>
              Effortlessly manage and publish vendor products, with automated store and inventory
              synchronization.
            </p>
          </div>
        </div>
        <div style={{ height: '48px' }} />
      </div>
      <div className={styles.part_4}>
        <Reveal keyframes={customAnimation} duration={500} delay={0} triggerOnce>
          <h1>Connect With Our</h1>
          <h1>Fashion Community</h1>
        </Reveal>
        <Reveal keyframes={customAnimation} duration={500} delay={100} triggerOnce>
          <p>Rich customer engagement, seamlessly bridging online and offline interactions</p>
        </Reveal>
        <Reveal keyframes={customAnimation} duration={500} delay={200} triggerOnce>
          <button
            style={{ marginTop: '40px' }}
            className={styles.joinButton}
            onClick={() => setShowModal(true)}
          >
            <span>Join Now</span>
            <div className={styles.arrow}>
              <ArrowRightSVG />
            </div>
          </button>
        </Reveal>
      </div>
      <div className={styles.footer}>
        <img src='/assets/Monsoon.png' />
      </div>
      <Modal
        open={showModal}
        closable={true}
        onCancel={() => setShowModal(false)}
        footer={null}
        centered
        width={'780px'}
        getContainer={false}
      >
        <ContactModal />
      </Modal>
    </div>
  );
};
